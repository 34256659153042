.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.blog-card-view {
  max-height: 70vh;
  overflow-y: auto !important;
}

.blog-card-view:hover {
  overflow: auto !important;
  /* or overflow-y: auto; if you only want vertical scrolling */
}

.nav-link {
  text-decoration: none;
  /* Remove underline */
  color: inherit;
  /* Inherit text color */
  background-color: transparent;
  /* Transparent background */
  border: none;
  /* Remove border */
  cursor: pointer;
  /* Change cursor to pointer */
  padding: 0.5rem 1rem;
  /* Adjust padding */
  font-size: 1rem;
  /* Adjust font size */
  transition: background-color 0.3s ease;
  /* Add transition effect */
}

.nav-link:hover {
  background-color: rgba(0, 0, 0, 0.1);
  /* Add hover background color */
}

.nav-link:focus {
  outline: none;
  /* Remove outline on focus */
}

.nav-link.active {
  font-weight: bold;
  /* Example: Make the active link bold */
  color: #ff0000;
  /* Example: Change the color of the active link */
}

.contact-me {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: auto;
  /* Adjust width as needed */
  margin: 0 auto;
  margin-bottom: 15px;
  /* Adjust top margin as needed */
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

.contact-me h2 {
  margin-bottom: 20px;
}

.input-group {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.input-group input,
.input-group textarea {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-left: 10px;
}

.input-group .icon {
  font-size: 20px;
  margin-right: 10px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}